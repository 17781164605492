












import { Vue, Component, Prop } from 'vue-property-decorator'
import IconPriorityLowest from '@/components/UI/icons/IconPriorityLowest.vue'
import IconPriorityLow from '@/components/UI/icons/IconPriorityLow.vue'
import IconPriorityMedium from '@/components/UI/icons/IconPriorityMedium.vue'
import IconPriorityHigh from '@/components/UI/icons/IconPriorityHigh.vue'
import IconPriorityHighest from '@/components/UI/icons/IconPriorityHighest.vue'

interface Icon {
  component: any;
  color: string;
}

@Component({ name: 'TaskImportanceIcon' })
export default class TaskImportanceIcon extends Vue {
  @Prop({ type: Number, required: true }) readonly importanceLevel!: number
  @Prop({ type: String, required: false, default: null }) readonly size!: string
  iconPriorityLowest: any
  iconPriorityLow: any
  iconPriorityMedium: any
  iconPriorityHigh: any
  iconPriorityHighest: any

  created (): void {
    this.iconPriorityLowest = IconPriorityLowest
    this.iconPriorityLow = IconPriorityLow
    this.iconPriorityMedium = IconPriorityMedium
    this.iconPriorityHigh = IconPriorityHigh
    this.iconPriorityHighest = IconPriorityHighest
  }

  get icons (): Icon[] {
    return [
      {
        component: this.iconPriorityLowest,
        color: 'main-accent',
      },
      {
        component: this.iconPriorityLow,
        color: 'main-accent',
      },
      {
        component: this.iconPriorityMedium,
        color: 'warning',
      },
      {
        component: this.iconPriorityHigh,
        color: 'negative',
      },
      {
        component: this.iconPriorityHighest,
        color: 'negative',
      },
    ]
  }

  get icon (): Icon {
    return this.icons[this.importanceLevel] ?? { component: null, color: null }
  }
}
